import { Button, Layout, Input, Upload, Row, Col, Tree, message } from 'antd';
import { UploadOutlined, CloudSyncOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import './App.css';
import Answer from './Answer';
import { useEffect, useState, useRef } from 'react';
import accountLogo from './account.png';

// import static_data from './static_data.json';
import axios from 'axios';

const { TextArea } = Input;

const { Header, Content, Sider } = Layout;

const colorBgContainer = '#fff';

// const breakLines = { 12: true, 10: true, 37: true, 39: true, 41: true }

function getTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const timeString = `${hours}:${minutes}:${seconds}`;
  return timeString;
}

function App() {
  const boxRef = useRef();
  const scrollRef = useRef();
  const [time, setTime] = useState(getTime());
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [contentList, setContentList] = useState('');
  const [analyLoading, setAnalyLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [ids, setIds] = useState({ vectorStoreId: '', assistant: '', message: '' })

  const [docKeys, setDocKeys] = useState([]);

  const uploadProps = {
    name: 'file',
    action: 'http://106.14.18.119:3033/upload',
    accept: '.pdf, .xls, .xlsx',
    onChange(info) {
      setUploadLoading(true);
      // setTimeout(function () {
      //   setUploadList(info.fileList)
      // }, 2000)
      if (info.file.status === 'done') {
        setUploadLoading(false)
        uploadList.push(info.file.name);
        setUploadList(uploadList);
        message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === 'error') {
        setUploadLoading(false)
        message.error(`${info.file.name} 文件上传失败`);
      }
    },
    showUploadList: false
  };

  useEffect(function () {
    const interval = setInterval(function () {
      setTime(getTime());
    }, 30000)
    return () => {
      clearInterval(interval);
    }
  }, [])

  // function scrollDom() {
  //   if (boxRef.current) {
  //     const outHeight = boxRef.current.scrollHeight;
  //     const _textArea = scrollRef.current.resizableTextArea.textArea;
  //     const scrollHeight = _textArea.scrollHeight;
  //     _textArea.scrollTop = scrollHeight - outHeight;
  //   }
  // }

  // function addContent(_list) {
  //   setContentList(_list);
  //   setTimeout(function () {
  //     scrollDom();
  //   }, 300)
  // }

  const handleDownload = () => {
    // 创建一个下载链接
    const link = document.createElement('a');
    link.href = 'http://106.14.18.119:3033/download/' + ids.message; // 替换为你要下载的文件的 URL
    link.download = ids.message; // 文件下载后的名称
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="App">
      <Layout style={{ height: '100%', overflow: 'hidden' }}>
        <Header className="header">
          <Row style={{ width: '100%' }}>
            <Col span={4} className="title">内容智创平台</Col>
            <Col span={2} className="subTitle">无标题</Col>
            <Col span={4} className="save">
              <CloudSyncOutlined style={{ marginRight: 8 }} />
              已保存 {time}
            </Col>
            <Col span={14}>
              <div style={{ display: 'flex', float: 'right', marginTop: 14 }}>
                <div className="account" style={{ backgroundImage: `url(${accountLogo})` }}></div>
                <Button className="headButton">一键成片</Button>
                <Button
                  className="headButton"
                  onClick={function () {
                    setTime(getTime());
                  }}
                >保 存</Button>
                <Button
                  className="headButton"
                  type="primary"
                  onClick={handleDownload}
                  disabled={!ids.message}
                >导 出</Button>
              </div>
            </Col>
          </Row>
        </Header>
        <Layout className="wrap" >
          <Sider width={220} className="childWrap" style={{ background: colorBgContainer, paddingLeft: 24 }}>
            <div className="uploadTitle">大纲</div>
            {contentList.length > 0 && <Tree
              // defaultExpandedKeys={['0-0-0', '0-0-1']}
              // defaultSelectedKeys={['0-0-0', '0-0-1']}
              // defaultCheckedKeys={['0-0-0', '0-0-1']}
              // onSelect={onSelect}
              // onCheck={onCheck}
              checkable={false}
              treeData={docKeys.map((ele) => ({ title: ele, key: ele }))}
              style={{ fontSize: 12 }}
            />}
          </Sider>
          <Layout className="childWrap">
            <div style={{
              padding: '0px 24px',
              height: '100%'
            }}>
              <Content
                style={{
                  padding: 12,
                  background: colorBgContainer,
                  borderRadius: 12,
                  boxSizing: "content-box",
                  display: 'flex'
                }}
              >
                <div style={{ width: 80, marginTop: 6 }} className="uploadTitle">公司名称：</div>
                <Input
                  style={{ width: 300 }}
                  size="small"
                  placeholder="请输入公司名称"
                  value={companyName}
                  onChange={function (e) {
                    setCompanyName(e.target.value)
                  }}
                />
              </Content>
              <Content
                style={{
                  marginTop: 12,
                  padding: 12,
                  background: colorBgContainer,
                  borderRadius: 12,
                  boxSizing: "content-box"
                }}
              >
                <div className="uploadTitle">参考稿源</div>
                <div className="uploadWrap">
                  {uploadList.map((ele) => {
                    return <div className="sourceWrap" key={ele}>
                      <div key={ele.name} className="source">{ele}</div>
                      <div className="sourceIcon">
                        {ele.indexOf('.pdf') > -1 ? <FilePdfOutlined /> : <FileExcelOutlined />}
                      </div>
                    </div>
                  })}
                  {/* <div className="source">使用第七部分信息.pdf</div> */}
                  <Upload {...uploadProps}>
                    <Button
                      // size='small'
                      icon={<UploadOutlined />}
                      style={{ marginTop: 16 }}
                      loading={uploadLoading}
                    >上传文件</Button>
                  </Upload>
                  <Button
                    type="primary"
                    style={{ marginTop: 16, marginLeft: 12 }}
                    disabled={!(uploadList.length === 2 && (
                      (uploadList[0].indexOf('.pdf') > -1 && uploadList[1].indexOf('.xlsx') > -1)
                      || (uploadList[0].indexOf('.xlsx') > -1 && uploadList[1].indexOf('.pdf') > -1)
                    ) && companyName)}
                    onClick={async function () {
                      setAnalyLoading(true);
                      // setTimeout(function () {
                      //   setAnalyLoading(false);
                      //   setContentList(content.join('\n'));
                      // }, 20000)
                      const _pdf = uploadList.find((ele) => ele.indexOf('.pdf') > -1);
                      const _excel = uploadList.find((ele) => ele.indexOf('.xlsx') > -1);
                      console.log('_pdf', _pdf, _excel, companyName)
                      const _reult = await axios({
                        url: 'http://106.14.18.119:8000/extract',
                        method: "post",
                        headers: {
                          "Content-Type": "application/json"
                        },
                        data: JSON.stringify({
                          "pdfFileName": "/root/uploads/" + _pdf,
                          "excelFileName": "/root/uploads/" + _excel,
                          "companyName": companyName
                        })
                      })
                      if (_reult.status === 200 && _reult.data) {
                        // setData(_reult.data);
                        const static_data = _reult.data;
                        const docJson = JSON.parse(static_data.docJson);
                        const _docKeys = Object.keys(docJson);

                        const table1 = static_data.docTableText['table3.1'];
                        const table2 = static_data.docTableText['table3.2'];
                        const table3 = static_data.docTableText['table4.1'];
                        const table4 = static_data.docTableText['table4.2'];
                        const table5 = static_data.docTableText['table6'];

                        const content = JSON.parse(static_data.docText);
                        content.splice(14, 0, table1);
                        content.splice(17, 0, table2);
                        content.splice(29, 0, table3);
                        content.splice(32, 0, table4);
                        content.splice(43, 0, table5);

                        setDocKeys(_docKeys);
                        setContentList(content.join('\n'));

                        setIds({
                          vectorStoreId: static_data.vectorStoreId,
                          assistant: static_data.assistant,
                          message: static_data.message
                        });
                      }
                      setAnalyLoading(false);
                    }}
                    loading={analyLoading}
                  >AI报告</Button>
                </div>
              </Content>
              <div
                ref={boxRef}
                className="contentWrap"
                style={{
                  marginTop: 16,
                  padding: '12px',
                  background: colorBgContainer,
                  borderRadius: 12,
                  boxSizing: "content-box"
                }}
              >
                {/* <Content
                  ref={scrollRef}
                  className="scrollWrap"
                >
                  {contentList.map((ele, i) => {
                    return <p
                      key={'p' + i}
                      style={{ whiteSpace: breakLines[i] ? 'break-spaces' : 'pre' }}
                    >{ele}</p>
                  })}
                </Content> */}
                <TextArea
                  ref={scrollRef}
                  value={contentList}
                  onChange={(e) => setContentList(e.target.value)}
                  placeholder="上传文件并点击分析文本按钮"
                  // autoSize={{ minRows: 20, maxRows: 20 }}
                  // autoSize={false}
                  style={{ fontSize: 12, height: '100%' }}
                />
              </div>
            </div>
          </Layout>
          <div className="right">
            <Answer companyName={companyName} ids={ids} />
          </div>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
