import { useEffect, useState, memo, useRef } from "react";
import styles from "./index.module.scss";
import ChatBot from './ChatBot';

const App = memo(function App({ companyName, ids }) {

    return (
        <div className={styles.wrap}>
            <ChatBot companyName={companyName} ids={ids} />
        </div>
    );
});

export default App;
