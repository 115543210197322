import { useEffect, useState, memo, useRef } from "react";
import axios from "axios";
import { UndoOutlined, LikeOutlined } from '@ant-design/icons';
import styles from "./index.module.scss";
import { Button, Input, Spin, message } from "antd";
import userSvg from './User.png';
import aiSvg from './AI.png';
import searchSvg from './search.svg';
// import Source from '../../components/source';
// import ChatSDK from '../../components/chat_sdk'

const App = memo(function App({ companyName, ids }) {
    const [messages, setMessages] = useState([
        { text: '你好我是AI助手，您可以跟我对话了解更多内容。', isUser: false }
    ]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const textRef = useRef('');
    const boxRef = useRef();
    const scrollRef = useRef();
    const answerRef = useRef('');
    const messageRef = useRef([]);
    const timeoutRef = useRef();
    const isSameText = useRef('');

    function scrollDom() {
        if (boxRef.current) {
            const outHeight = boxRef.current.scrollHeight;
            const scrollHeight = scrollRef.current.scrollHeight;
            scrollRef.current.scrollTop = scrollHeight - outHeight;
        }
    }

    function sycScroll() {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(function () {
            scrollDom();
        }, 300)
    }

    const handleSearch = async (question) => {
        if (!question) return;
        console.log(question)
        messageRef.current = [...messages, { text: question, isUser: true }];
        setMessages(messageRef.current);
        setInput('');
        setLoading(true);

        sycScroll();

        const _reult = await axios({
            url: 'http://106.14.18.119:8000/ask',
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify({
                "question": question,
                "vectorStoreId": ids.vectorStoreId,
                "assistant": ids.assistant,
                "companyName": companyName
            })
        })

        if (_reult.status === 200 && _reult.data) {
            // textRef.current = '';
            setLoading(false);
            console.log('_reult', _reult.data.text)
            // textRef.current += _reult.data.text;
            messageRef.current = [
                ...messages,
                { text: question, isUser: true }, { text: _reult.data.text, isUser: false, buttonProps: [question, _reult.data.text] }
            ];
            setMessages(messageRef.current);
            sycScroll();
        }

        // fetchEventSource('http://106.14.18.119:8000/ask', {
        //     method: 'POST',
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         "question": question,
        //         "vectorStoreId": "vs_tAI4y8LGQxWIoyVtnKqaWOzz",
        //         "assistant": "asst_beNqb3D5U2U6oiwj0JS272nm"
        //     }),
        //     onopen(event) {
        //         messageRef.current = [...messages, { text: question, isUser: true }, { text: '', isUser: false }];
        //         setMessages(messageRef.current);
        //     },
        //     onmessage(event) {
        //         console.log('event', event)
        //         const _data = JSON.parse(event.data);
        //         console.log('_data', _data)

        //         // if (_data) {
        //         //     console.log('ttttt', _data)
        //         //     if (_data.response) {
        //         //         textRef.current += _data.response;
        //         //         scrollDom();
        //         //         messageRef.current = [...messages, { text: question, isUser: true }, { text: textRef.current, isUser: false }];
        //         //         setMessages(messageRef.current);
        //         //     } else if (_data.source_documents && _data.source_documents.length > 0) {
        //         //         answerRef.current = _data.history[0][1];
        //         //         let _source = _data.source_documents.map((ele) => {
        //         //             return {
        //         //                 name: ele.file_name,
        //         //                 desc: ele.content,
        //         //                 open: false
        //         //             }
        //         //         })
        //         //         if (answerRef.current.indexOf('无法回答') > -1) {
        //         //             _source = [];
        //         //         }
        //         //         scrollDom();
        //         //         messageRef.current = [...messages, { text: question, isUser: true }, { text: textRef.current, isUser: false, source: _source }];
        //         //         setMessages(messageRef.current);
        //         //     }
        //         // }
        //     },
        //     onerror(event) {
        //         console.log('服务异常', event);
        //         textRef.current = '';
        //         scrollDom();
        //         setLoading(false);
        //     },
        //     onclose() {
        //         console.log('服务关闭');
        //         //     ChatSDK.stopTTS();
        //         //     ChatSDK.drivenText(textRef.current);
        //         //     textRef.current = '';
        //         //     setLoading(false);

        //         //     if (timeoutRef.current) clearTimeout(timeoutRef.current);
        //         //     timeoutRef.current = setTimeout(function () {
        //         //         scrollDom();
        //         //     }, 300)

        //         //     if (answerRef.current.indexOf('无法回答') > -1) return;

        //         //     axios({
        //         //         method: "post",
        //         //         url: config.apiUrl + 'api/local_doc_qa/counter_question',
        //         //         data: {
        //         //             q: question,
        //         //             a: answerRef.current
        //         //         },
        //         //     }).then(function (_result) {
        //         //         console.log('_result', _result, answerRef.current)
        //         //         answerRef.current = '';
        //         //         if (_result.data && _result.data.question) {
        //         //             const list = _result.data.question.split('\n');
        //         //             const _list = list.map((ele) => {
        //         //                 let _text = ele.replace('问题1：', '');
        //         //                 _text = _text.replace('问题2：', '');
        //         //                 _text = _text.replace('问题3：', '');
        //         //                 return _text;
        //         //             })
        //         //             const _clone = JSON.parse(JSON.stringify(messageRef.current));
        //         //             _clone[messageRef.current.length - 1].questions = _list;
        //         //             setMessages(_clone);

        //         //             if (timeoutRef.current) clearTimeout(timeoutRef.current);
        //         //             timeoutRef.current = setTimeout(function () {
        //         //                 scrollDom();
        //         //             }, 300)
        //         //         }
        //         //     })
        //     }
        // })
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.chartBox} ref={boxRef}>
                <div className={styles.scrollWrap} ref={scrollRef}>
                    {messages.map((msg, i) => (
                        <div className={styles.message} key={i}>
                            <img className={styles.icon} src={msg.isUser ? userSvg : aiSvg}></img>
                            <div>
                                <div
                                    className={styles.text}
                                    style={{
                                        backgroundColor: msg.isUser ? 'rgb(79,130,237)' : '#fff',
                                        color: msg.isUser ? '#fff' : '#000',
                                    }}
                                >{msg.text}</div>
                                {msg.buttonProps && <div style={{ marginTop: 12 }}>
                                    <Button
                                        size="small"
                                        icon={<UndoOutlined />}
                                        onClick={function () {
                                            handleSearch(msg.buttonProps[0]);
                                        }}
                                    >重新生成</Button>
                                    <Button
                                        size="small"
                                        style={{ marginLeft: 12 }}
                                        icon={<LikeOutlined />}
                                        onClick={function () {
                                            // if (isSameText.current === msg.buttonProps[1]) return;
                                            // const _clone = JSON.parse(JSON.stringify(contentList));
                                            // _clone.push(msg.buttonProps[1]);
                                            // addContent(_clone);
                                            const _string = msg.buttonProps[1];
                                            // addContent(_string);
                                            // isSameText.current = msg.buttonProps[1];
                                            navigator.clipboard.writeText(_string)
                                                .then(() => {
                                                    message.success('文本已复制到剪贴板！');
                                                })
                                                .catch((error) => {
                                                    message.error('复制失败！');
                                                });
                                        }}
                                    >采 纳</Button>
                                </div>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Spin
                spinning={loading}
            >
                <div
                    className={styles.searchWrap}
                >
                    <Input.TextArea
                        className={styles.search}
                        value={input}
                        placeholder="提问"
                        onChange={function (e) {
                            setInput(e.target.value);
                        }}
                        variant="borderless"
                        // size="large"
                        autoSize={{
                            maxRows: 2,
                            minRows: 2
                        }}
                    />
                    <img
                        className={styles.searchImg}
                        src={searchSvg}
                        onClick={function () {
                            handleSearch(input);
                        }}
                    />
                </div>
            </Spin>
        </div >
    );
});

export default App;
